const sdkUrlList = [
    {
        BASEURL: 'http://192.168.68.100:8081/sdkDialog/#/sdk/main',
    },
    {
        BASEURL: 'https://game.daoyigame.com/sdkDialog/#/sdk/main',
    },
];

let sdkUrlArr;

sdkUrlArr = sdkUrlList[1]
let sdkUrl = sdkUrlArr.BASEURL
export default sdkUrl
