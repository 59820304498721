<template>
    <div style="height: 250px;background-color: #444444;width: 100%;display: flex;flex-direction: column;align-items: center;color: #FFFFFF">
        <div style="display: flex;align-items: center;margin-top: 30px;" class="no-select">
<!--            <span>关于我们</span>
            <div class="column-line"></div>-->
            <span @click="openPrivacy">隐私政策</span>
            <div class="column-line"></div>
            <span @click="openUser">用户协议</span>
<!--            <div class="column-line"></div>
            <span>平台公告</span>-->
        </div>

        <div style="color: #aaaaaa;font-size: 14px;margin-top: 20px;display: flex;flex-direction: column">
            <span>copyright © 2024 daoyigame.com All Rights Reserved</span>
            <span style="margin-top: 15px;">深圳星游纪元科技有限公司</span>
            <span style="margin-top: 15px;">联系地址： 深圳市龙岗区坂田街道坂田社区黄金山街13号A栋203</span>
            <span style="margin-top: 15px;">粤ICP备2022153221号-1</span>
            <!--<div style="display: flex;justify-content: center;margin-top: 15px;">
                <img src="../assets/gonganbu.png" style="width: 20px;">
                <a style="color: #aaaaaa;cursor:pointer;margin-left: 5px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=">X公网安备 XXXXXXXXXXXXX号</a>
            </div>-->
            <span style="margin-top: 15px;">抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</span>
        </div>



    </div>
</template>

<script>
    import webConfig from "@/api/config";
    import notice from "@/api/notice";

    export default {
        name: "HomeFooter",
        data() {
            return {
                webConfig: {},
            }
        },
        mounted() {
            this.version = localStorage.getItem('version');
            this.webConfig = webConfig;
        },
        methods : {
            openUser() {
                window.open(this.webConfig.agreement, '_blank');
            },
            openPrivacy() {
                window.open(this.webConfig.privacy, '_blank');
            },
        }
    }
</script>

<style scoped>
    .column-line {
        display: inline-block;
        width: 1px;
        height: 15px;
        background-color: #ffffff;
        margin-left: 10px;
        margin-right: 10px;
    }
    .no-select {
        user-select: none;
        cursor: pointer;
    }
</style>