<template>
    <van-tabbar class="p-hidden-view" v-model="active" active-color="#7a69be" inactive-color="#aaaaaa" @change="change">
        <van-tabbar-item>
            <span>发现</span>
            <template #icon="props">
                <img v-if="props.active" src="../assets/tabbar/find_active.png" style="width: 25px;height: 25px;">
                <img v-else src="../assets/tabbar/find.png" style="width: 25px;height: 25px;">
            </template>
        </van-tabbar-item>

        <van-tabbar-item>
            <span>找游戏</span>
            <template #icon="props">
                <img v-if="props.active" src="../assets/tabbar/game_active.png" style="width: 25px;height: 25px;">
                <img v-else src="../assets/tabbar/game.png" style="width: 25px;height: 25px;">
            </template>
        </van-tabbar-item>

        <van-tabbar-item>
            <span>家族</span>
            <template #icon="props">
                <img v-if="props.active" src="../assets/tabbar/notice_active.png" style="width: 25px;height: 25px;">
                <img v-else src="../assets/tabbar/notice.png" style="width: 25px;height: 25px;">
            </template>
        </van-tabbar-item>

        <van-tabbar-item>
            <span>我的</span>
            <template #icon="props">
                <img v-if="props.active" src="../assets/tabbar/me_active.png" style="width: 25px;height: 25px;">
                <img v-else src="../assets/tabbar/me.png" style="width: 25px;height: 25px;">
            </template>
        </van-tabbar-item>

    </van-tabbar>
</template>

<script>
    import { Tabbar, TabbarItem } from 'vant';
    import Vue from 'vue';
    Vue.use(Tabbar);
    Vue.use(TabbarItem);

    export default {
        name: "HomeTabbar",
        mounted() {
            let path = this.$route.path;
            if (path === '/index') {
                this.active = 0;
            } else if (path === '/home/gameList') {
                this.active = 1;
            } else if (path === '/home/team') {
                this.active = 2;
            } else if (path === '/home/me') {
                this.active = 3;
            }
        },
        data() {
            return {
                active: 0,
            }
        },
        methods: {
            change(v) {
                if (v === 0) {
                    this.$router.push("/index");
                } else if (v === 1) {
                    this.$router.push("/home/gameList");
                } else if (v === 2) {
                    this.$router.push("/home/team");
                } else if (v === 3) {
                    this.$router.push("/home/me");
                }
            },
        }
    }
</script>

<style scoped>

    @media screen and (min-width: 900px) {
        .p-hidden-view {
            display: none;
        }
    }
    /deep/.van-tabbar-item--active {
        font-weight: bold;
    }

</style>