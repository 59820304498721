<template>
    <div class="main-view" ref="mainView">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <img  class="m-hidden-view" src="../../assets/logo/bg.png" style="width: 100%;position: absolute;top: 0;left: 0;z-index: -1" :style="{'height': height}"/>

        <div class="m-title" ref="mainTitle">
            <div style="display: flex;height: 70px;align-items: center;justify-content: space-between;width: 100%;">
                <span>游戏推荐</span>
                <img  @click="goAppSearch" v-show="showSearchIcon" src="../../assets/search.png" style="width: 18px;height: 18px;margin-right: 15px;">
            </div>
        </div>

        <div class="home-content">
            <div @click="goAppSearch" class="search-input-button p-hidden-view">
                <img src="../../assets/search.png" style="width: 18px;height: 18px;">
                <span style="margin-left: 8px;">灵眸极速服</span>
            </div>

            <div style="width: 100%;top: 400px;margin-top: 320px"  class="m-hidden-view">
                <div style="display: flex;align-items: center;margin: 0 auto;">
                    <div class="code-view">
                        <img src="../../assets/app_url_code.png" style="width: 105px;height: 105px;">
                        <span style="font-size: 10px;color: #666666;margin-top: 10px;">扫码下载app</span>
                    </div>

                    <div style="margin-left: 50px;">
                        <div class="download-button" @click="goAppDownloadPage">
                            <img src="../../assets/android.png" style="width: 20px;height: 20px;margin-right: 10px;">
                            安卓版下载
                        </div>
                        <div v-if="false" class="download-button" style="margin-top: 20px">
                            <img src="../../assets/iOS.png" style="width: 20px;height: 20px;margin-right: 10px;">
                            苹果版下载
                        </div>
                    </div>
                </div>
            </div>

            <div class="top-game-view">
                <div class="main-recommend-view" @click="toDetail(leftGame.gameId)">
                    <div style="display: flex;align-items: center;">
                        <img :src="leftGame.icon" class="game-icon-img">
                        <div style="display: flex;height: 55px; margin-left: 15px;flex-direction: column;justify-content: space-between;align-items: flex-start;">
                            <span class="top-game-name">{{leftGame.gameName}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in leftGame.tagList">{{tag}}</span>
                            </div>
                        </div>
                    </div>
                    <van-image :src="leftGame.gameImage" style="width: 100%;height: 230px;border-radius: 10px;margin-top: 10px;"/>
<!--                    <div style="color: #aaaaaa;text-align: left;margin-top: 20px"></div>-->
                </div>

                <div  class="main-recommend-view" @click="toDetail(rightGame.gameId)">
                    <div style="display: flex;align-items: center;">
                        <img :src="rightGame.icon" class="game-icon-img">
                        <div style="display: flex;height: 55px; margin-left: 15px;flex-direction: column;justify-content: space-between">
                            <span class="top-game-name">{{rightGame.gameName}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in rightGame.tagList">{{tag}}</span>
                            </div>
                        </div>
                    </div>
                    <img :src="rightGame.gameImage" style="width: 100%;height: 230px;border-radius: 10px;margin-top: 10px;" fit="cover"/>
<!--                    <div style="color: #aaaaaa;text-align: left;margin-top: 20px">激情PK，万人在线</div>-->
                </div>
            </div>

            <div class="center-game-view">
                <div class="top-view-head">
                    <div class="top-view-name_pre"></div>
                    <div class="top-vew-name">
                        精品游戏
                    </div>
                </div>

                <div class="bottom-game-list">
                    <div v-for="item in bottomGameList" @click="toDetail(item.gameId)" class="hor-game-view">
                        <img :src="item.gameImage" style="width: 100%;height: 160px;border-radius: 10px;">
                        <span class="game-name-one-line m-margin-top-5"  style="margin-top: 10px;">{{item.gameName}}</span>
                        <div class="game-tag-view m-margin-top-5" style="margin-top: 10px;">
                            <span v-for="tag in item.tagList">{{tag}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-game-view">
                <div class="top-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            热游榜
                        </div>
                    </div>
                    <div v-for="(item,index) in hotGameList" @click="toDetail(item.gameId)" class="top-game-item">
                        <img :src="item.icon" class="main-view-game-img">
                        <div class="main-view-game-info">
                            <span class="game-name-one-line">{{item.gameName}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in item.tagList">{{tag}}</span>
                            </div>
                            <div style="display: flex;justify-content: flex-start">
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon v-if="index < 3 " name="star" color="#f55050"/>
                                <van-icon v-if="index >= 3 " name="star" color="#dddddd"/>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="top-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            新游上线
                        </div>
                    </div>
                    <div v-for="(item,index) in newGameList" @click="toDetail(item.gameId)" class="top-game-item">
                        <img :src="item.icon" class="main-view-game-img">
                        <div class="main-view-game-info">
                            <span class="game-name-one-line">{{item.gameName}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in item.tagList">{{tag}}</span>
                            </div>
                            <div style="display: flex;justify-content: flex-start">
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon v-if="index < 3 " name="star" color="#f55050"/>
                                <van-icon v-if="index >= 3 " name="star" color="#dddddd"/>
                            </div>
                        </div>
                    </div>

                </div>

<!--                <div class="top-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            玩家推荐
                        </div>
                    </div>
                    <div v-for="(item,index) in recommendGameList" @click="toDetail(item.gameId)" class="top-game-item">
                        <img :src="item.icon" class="main-view-game-img">
                        <div class="main-view-game-info">
                            <span class="game-name-one-line">{{item.gameName}}</span>
                            <div class="game-tag-view">
                                <span v-for="tag in item.tagList">{{tag}}</span>
                            </div>
                            <div style="display: flex;justify-content: flex-start">
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon name="star" color="#f55050"/>
                                <van-icon v-if="index < 3 " name="star" color="#f55050"/>
                                <van-icon v-if="index >= 3 " name="star" color="#dddddd"/>
                            </div>
                        </div>

                    </div>
                </div>-->
            </div>


        </div>
        <home-footer  class="m-hidden-view"></home-footer>

        <home-tabbar></home-tabbar>
    </div>
</template>

<script>
    import {request} from "@/api/request";

    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import homeTabbar from "@/components/HomeTabbar";

    import demo2Img from "@/assets/demo_2.png";
    import {Toast} from "vant";
    import "@/css/home.css";

    export default {
        name: "homeMain",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
            homeTabbar,
        },
        data() {
            return {
                step: 1,
                demo2Img,
                pageGameInfo: {},
                leftGame: {},
                rightGame: {},
                hotGameList: [],
                newGameList: [],
                recommendGameList: [],
                bottomGameList: [],
                height: '0px',
                showSearchIcon: false,
            }
        },
        mounted() {
            if(this.$route.query.thirdChannel) {
                localStorage.setItem('channel', this.$route.query.thirdChannel);
            }
            else if (this.$route.query.channel) {
                localStorage.setItem('channel', this.$route.query.channel);
            }

            if (this.$route.query.from) {
                localStorage.setItem('from', this.$route.query.from);
            }
            if (this.$route.query.version) {
                localStorage.setItem('version', this.$route.query.version);
            }

            this.height = (window.innerHeight) + 'px';
            this.initPage();
        },
        methods: {
            initPage() {
                this.showLoading = true;
                let options = {
                    url:"/web/getHomeGame",
                    data: {}
                };
                request(options).then(res => {
                    this.showLoading = false;
                    if (res.data.success) {
                        let data = res.data.data;
                        this.leftGame = data.leftInfo;
                        this.rightGame = data.rightInfo;
                        this.hotGameList = data.hotList;
                        this.newGameList = data.newList;
                        this.recommendGameList = data.recommendList;
                        this.bottomGameList = data.bottomList;
                    }
                    else {
                        Toast(res.data.error.message);
                    }
                });

                this.$refs.mainView.addEventListener('scroll', this.onPageScroll);
            },
            toDetail(gameId) {
                this.$router.push('/detail/' + gameId);
            },
            goAppSearch() {
                this.$router.push('/home/appSearch');
            },
            onPageScroll() {
                let scrollTop = this.$refs.mainView.scrollTop;
                this.showSearchIcon = scrollTop !== 0;
            },
            goAppDownloadPage() {
                this.$router.push("/detail/100");
            }
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-y: auto;
        background-color: #eaeef3;
        position: relative;
        z-index: 0;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    .top-game-view {
        display: flex;
        width: 100%;
        margin: 80px auto 0;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    @font-face{
        font-family:myFont;
        src: url(../../assets/myFont.TTF);
    }
    .top-game-item {
        display: flex;
        align-items: center;
        padding: 15px 30px;
    }
    .top-game-item:hover .game-name-one-line {
        color: #f55050;
    }
    .hor-game-view:hover .game-name-one-line {
        color: #f55050;
    }
    .game-name-one-line {
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-align: left;
        -webkit-line-clamp: 1; /* 这里是超出几行省略 */
        font-size: 20px;
        font-weight: bold;
        color: #333333
    }
    .top-view-name_pre {
        width: 5px;
        height: 25px;
        background-color: #f55050;
    }
    .top-vew-name {
        text-align: left;
        font-family: myFont,serif;
        color: #333333;
        user-select: none;
        margin-left: 10px;
    }
    .top-view-head {
        height: 80px;
        font-size: 35px;
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
    .main-recommend-view {
        width: 49%;
        background-color: #ffffff;
        border-radius: 10px;
        cursor: pointer;
        transition: transform 0.3s ease; /*添加过渡效果*/
        padding: 30px;
        box-sizing: border-box;
    }
    .main-recommend-view:hover {
        transform: translateY(-25px); /*向上移动5像素*/
    }
    /deep/.main-recommend-view img {
        border-radius: 10px;
    }
    .game-tag-view {
        display: flex;
        color: #f3671c;
    }
    .game-tag-view span {
        margin-right: 10px;
    }
    .top-view {
        width: 49%;
        height: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding-bottom: 30px;
        cursor: pointer;
    }
    .hor-game-view {
        width: 30%;
        padding-bottom: 5px;
    }
    .download-button {
        width: 180px;
        height: 50px;
        border-radius: 40px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        padding: 0 25px;
        box-sizing: border-box;
        color: #444444;
        user-select: none;
        cursor: pointer;
    }
    .code-view {
        width: 125px;
        height: 145px;
        background-color: #ffffff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .search-input-button {
        height: 45px;
        background-color: #ffffff;
        border-radius: 35px;
        margin: 110px 25px 0;
        display: flex;
        align-items: center;
        padding: 0 15px;
        color: #aaaaaa;
        font-size: 16px;
    }
    .top-game-name {
        font-size: 20px;
        font-weight: bold;
        color: #333333
    }
    .game-icon-img {
        width: 70px;
        height: 70px;
        border-radius: 10px;
    }
    .main-game-view {
        display: flex;
        width: 100%;
        margin: 20px auto 20px;
        justify-content: space-between;
    }

    .main-view-game-img {
        width: 110px;
        height: 110px;
        border-radius: 20px;
    }
    .main-view-game-info {
        display: flex;
        height: 90px;
        margin-left: 15px;
        flex-direction: column;
        justify-content: space-between;
    }
    .center-game-view {
        background-color: #ffffff;
        border-radius: 10px;
        cursor: pointer;
        width: 100%;
        margin-top: 20px;
    }
    .bottom-game-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 20px;
        margin-top: 10px;
    }
    @media screen and (max-width: 900px) {
        .main-view {
        }
        .m-title {
            display: flex;
        }
        .p-title {
            display: none;
        }

        .m-hidden-view {
            display: none !important;
        }
        .top-game-view{
            flex-direction: column;
            margin-top: 10px;
        }

        .main-recommend-view{
            width: 90%;
            margin: 10px auto 0;
            padding: 15px 15px;
        }
        .main-recommend-view:hover {
            transform: unset;
        }
        .top-game-name {
            font-size: 16px;
        }
        .game-tag-view {
            font-size: 12px;
        }
        .game-icon-img {
            width: 60px;
            height: 60px;
            border-radius: 6px;
        }
        .main-game-view {
            margin: 0 auto 60px;
            flex-direction: column;
        }
        .top-view {
            width: 90%;
            margin: 10px auto 0;
            padding-bottom: 10px;
        }
        .top-view-head {
            height: 50px;
            font-size: 25px;
            padding-left: 20px;
        }
        .main-view-game-img {
            width: 70px;
            height: 70px;
            border-radius: 10px;
        }
        .top-game-item {
            padding: 10px 25px;
        }
        .main-view-game-info {
            height: 70px;
            margin-left: 10px;
        }
        .game-name-one-line {
            font-size: 16px;
        }
        .center-game-view {
            width: 90%;
            margin: 10px auto 0;
        }
        .bottom-game-list {
            align-items: center;
            justify-content: space-between;
            padding: 0 30px 0;
            flex-direction: column;
        }
        .hor-game-view {
            width: 100%;
            padding-bottom: 20px;
        }
        .m-margin-top-5 {
            margin-top: 5px !important;
        }
    }

    @media screen and (min-width: 900px) {
        .p-hidden-view {
            display: none;
        }
    }
</style>