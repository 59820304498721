<template>
    <div class="all-page">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="user-info-view border-bottom">

            <div v-if="isLogin" style="display: flex;align-items: center;">
                <img v-if="isLogin && imgUrl" :src="imgUrl" class="user-icon"/>
                <img v-else src="../../assets/default-user.png" class="user-icon"/>
                <div style="height: 55px;margin-left: 15px;display: flex;flex-direction: column; align-items: flex-start;justify-content: space-between;">
                    <div>
                        <span style="font-size: 22px;color: #333333;">{{ userName }} </span>
                        <span style="margin-left: 10px;font-size: 12px;color: #888888;">uid {{userId}}</span>
                        <span style="margin-left: 10px;font-size: 12px;color: #888888;">{{realNameCheck ? '已实名' : '未实名'}}</span>
                    </div>
                    <div style="font-size: 14px;color: #666666;">
                         <span style="">星元: ￥{{ ptb }}</span>
                         <span style="margin-left: 15px;">tel: {{ tel }}</span>
                    </div>
                </div>
            </div>

            <div v-else style="display: flex;align-items: center;font-size: 16px;">
                <img src="../../assets/default-user.png" class="user-icon">
                <div style="height: 50px;color: #888888;margin-left: 15px;display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between">
                    <span>
                        未登录
                    </span>
                    <span @click="openLoginPage" style="color: #f16868;cursor: pointer;font-size: 18px;">立即登录 / 注册</span>
                </div>
            </div>
        </div>

        <div class="p-title">
            <div style="height: 70px;padding-left: 30px;display: flex;align-items: center;color: #666666">
                <span>当前位置:</span>
                <span style="margin-left: 10px;">首页</span>
                <span style="margin-left: 10px;">></span>
                <span style="margin-left: 10px;">我的</span>
            </div>
        </div>

        <div @click="toMyGamePage" class="item-view border-top" style="margin-top: 20px;">
            <span>我的游戏</span>
            <van-icon name="arrow" color="#888888" />
        </div>
        <div class="view-center-line"><div></div></div>
<!--        <div @click="toMyTradePage" class="item-view">
            <span>我的交易</span>
            <van-icon name="arrow" color="#888888" />
        </div>
        <div class="view-center-line"><div></div></div>-->
        <div @click="toKfPage" class="item-view border-bottom">
            <span>在线客服</span>
            <van-icon name="arrow" color="#888888" />
        </div>

        <div @click="toRealNamePage" class="item-view border-top" style="margin-top: 20px;">
            <span>实名认证</span>
            <van-icon name="arrow" color="#888888" />
        </div>
        <div class="view-center-line"><div></div></div>
        <div @click="toTelPage" class="item-view">
            <span>绑定手机</span>
            <van-icon name="arrow" color="#888888" />
        </div>
        <div class="view-center-line"><div></div></div>
        <div @click="toPasswordPage" class="item-view border-bottom">
            <span>修改密码</span>
            <van-icon name="arrow" color="#888888" />
        </div>

        <div v-if="from === 'android_box'" @click="toAboutPage" class="item-view border-bottom border-top" style="margin-top: 20px;">
            <span>关于</span>
            <van-icon name="arrow" color="#888888" />
        </div>

        <div @click="logout" v-if="isLogin" class="item-view border-bottom border-top" style="margin-top: 20px;justify-content: center;color: #f34949;margin-bottom: 10px;">
            <span>退出登录</span>
        </div>

        <div class="me-login-view" v-if="showLogin" @click.self="showLogin = false">
            <home-login ></home-login>
        </div>


        <home-tabbar></home-tabbar>
        <home-footer class="m-hidden-view"></home-footer>
    </div>
</template>

<script>
    import {Toast, Dialog} from 'vant'

    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import homeTabbar from "@/components/HomeTabbar";
    import {request, webTokenRequest} from "@/api/request";
    import HomeLogin from "@/components/HomeLogin.vue";

    export default {
        name: "Me",
        components: {
            HomeLogin,
            homeNav,
            homeFooter,
            gzhCodeView,
            homeTabbar
        },
        data() {
            return {
                isLogin: false,
                showLogin: false,
                baseInfo: {},
                imgUrl: '',
                userName: '',
                tel: '未绑定',
                userId: 0,
                from: '',
                realNameCheck: '',
                ptb: '0',
            }
        },
        mounted() {
            this.initPage();
            this.getLoginInfo();
        },
        methods: {
            back() {
                this.$router.back();
            },
            resetPage() {
                this.getLoginInfo();
                this.showLogin = false;
            },
            initPage() {
                let baseInfoStorage = localStorage.getItem('web_baseInfo');
                if (baseInfoStorage) {
                    this.baseInfo = JSON.parse(baseInfoStorage);
                    this.isLogin = true;
                    this.userName = this.baseInfo.userName;
                    this.userId = this.baseInfo.userId;
                    this.realNameCheck = this.baseInfo.isUserCheck === '1';
                    this.baseInfo.phone && (this.tel = this.baseInfo.phone);
                    this.imgUrl = localStorage.getItem('web_headerImg');
                }
                this.from = localStorage.getItem('from');
            },
            getLoginInfo() {
                let webToken = localStorage.getItem('web_token');
                if (webToken) {
                    let options = {
                        url:"/web/getLoginInfo",
                        method: 'get'
                    };
                    webTokenRequest(options).then((res) => {
                        if (res.data.success === true) {
                            this.isLogin = true;
                            this.baseInfo = res.data.data;
                            this.userName = this.baseInfo.userName;
                            this.userId = this.baseInfo.userId;
                            this.ptb = this.baseInfo.ptb;
                            this.realNameCheck = this.baseInfo.isUserCheck === '1';
                            this.baseInfo.phone && (this.tel = this.baseInfo.phone);
                            this.imgUrl = localStorage.getItem('web_headerImg');
                            localStorage.setItem('web_baseInfo', JSON.stringify(this.baseInfo));
                        } else {
                            this.isLogin = false;
                            localStorage.removeItem('web_baseInfo')
                        }
                    });
                }
                else {
                    this.isLogin = false;
                }
            },
            openLoginPage() {
                this.showLogin = true;
            },
            toKfPage() {
                this.$router.push("/home/kf");
            },
            toMyGamePage() {
                if(!this.isLogin) {
                    this.openLoginPage();
                    return;
                }
                this.$router.push("/home/myGame");
            },
            toMyTradePage () {
                if(!this.isLogin) {
                    this.openLoginPage();
                    return;
                }
                this.$router.push("/trade/myTrade");
            },
            toRealNamePage () {
                if(!this.isLogin) {
                    this.openLoginPage();
                    return;
                }
                this.$router.push("/home/realName");
            },
            toTelPage () {
                if(!this.isLogin) {
                    this.openLoginPage();
                    return;
                }
                this.$router.push("/home/tel");
            },
            toPasswordPage () {
                if(!this.isLogin) {
                    this.openLoginPage();
                    return;
                }
                this.$router.push("/home/password");
            },
            toAboutPage () {
                this.$router.push("/home/about");
            },
            logout() {
                Dialog.confirm({
                    message: '确认退出登录吗？',
                }).then(() => {
                    let options = {
                        url: "/web/logout",
                        data: {}
                    };
                    request(options).then(res => {
                        Toast('已成功退出登录');
                        localStorage.removeItem('web_token');
                        localStorage.removeItem('web_user');
                        localStorage.removeItem('web_username');
                        localStorage.removeItem('web_headerImg');
                        this.resetPage();
                    });
                }).catch(() => {
                });
            },
        }
    }
</script>

<style scoped>
    .all-page {
        overflow: hidden;
        width: 100%;
        height: calc(100vh - 50px);
        overflow-y: auto;
        background-color: #eaeef3;
    }
    .user-info-view {
        width: 100%;
        height: 140px;
        background-color: #ffffff;
        display: flex;
        align-items: flex-end;
        padding: 0 0 40px 30px;
    }
    .p-title {
        width: 100%;
        max-width: 900px;
        height: 70px;
        background-color: #ffffff;
        border-radius: 8px;
        margin: 10px auto 0;
        z-index: 999;
        position: relative;
    }
    .user-icon {
        width: 60px;
        height: 60px;
        border-radius: 12px;
    }
    .item-view {
        background-color: #ffffff;
        height: 50px;
        display: none;
        align-items: center;
        justify-content: space-between;
        color: #555555;
        font-size: 16px;
        padding: 0 20px 0 30px;
        box-sizing: border-box;
        user-select: none;
        cursor: pointer;
    }
    .me-login-view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .border-bottom {
        border-bottom: 0.5px solid #e0e0e0;
    }
    .border-top {
        border-top: 0.5px solid #e0e0e0;
    }
    .view-center-line{
        width: 100%;
        height: 1px;
        background-color: #ffffff;
    }

    .view-center-line div{
        width: 100%;
        height: 1px;
        background-color: #e0e0e0;
        margin-left: 30px;
        transform: scaleY(.5);
    }

    @media screen and (max-width: 900px) {
        .m-hidden-view {
            display: none !important;
        }
        .p-title {
            display: none;
        }
        .m-title {
            display: flex;
        }
        .item-view {
            display: flex;
        }
    }
</style>