<template>
    <div style="height: 80px;background-color: #ffffff;width: 100%;">
        <div class="nav-center">
            <div class="logo-view">
                <img src="../assets/logo/logo-main.png" style="width: 130px;border-radius: 8px;" >
<!--                    <span style="margin-left: 7px;font-weight: bold;color: #efefef;font-style: italic;font-size: 25px;">星游平台</span>-->
            </div>
            <div class="menu-view">
                <div class="menu" :class="index === 1 && 'menu-active'"  @click="toMainPage">
                    首页
                    <div class="active-line" :class="preIndex > 1 ? 'from-right' : 'from-left'" v-show="index === 1"></div>
                </div>
                <div class="menu" :class="index === 2 && 'menu-active'" @click="toGameListPage">
                    游戏
                    <div class="active-line" :class="preIndex > 2 ? 'from-right' : 'from-left'" v-show="index === 2"></div>
                </div>
<!--                <div class="menu" :class="index === 3 && 'menu-active'" @click="toIntroPage">
                    攻略
                    <div class="active-line" :class="preIndex > 3 ? 'from-right' : 'from-left'" v-show="index === 3"></div>
                </div>-->
                <div class="menu" :class="index === 4 && 'menu-active'" @click="toKfPage">
                    客服
                    <div class="active-line" :class="preIndex > 4 ? 'from-right' : 'from-left'" v-show="index === 4"></div>
                </div>
                <div class="menu" :class="index === 5 && 'menu-active'" @click="toAppPage">
                    APP下载
                    <div class="active-line" :class="preIndex > 5 ? 'from-right' : 'from-left'" v-show="index === 5"></div>
                </div>
               <!-- <div class="menu" :class="index === 6 && 'menu-active'" @click="toAboutUsPage">
                    关于我们
                    <div class="active-line" :class="preIndex > 6 ? 'from-right' : 'from-left'" v-show="index === 6"></div>
                </div>-->
            </div>
            <div class="search-view">
                <div style="display: flex;justify-content: flex-start;align-items: center">
                    <img src="../assets/search.png" style="width: 18px;height: 18px;">
                    <span style="width: 2px;height: 20px;background-color: #dbdbdb;margin-left: 10px;"></span>
                    <input  type="text" class="search-input" v-model="searchName" :placeholder="defaultSearchName" @keyup.enter="searchGame">
                </div>
                <div class="search-button" @click="searchGame">搜索</div>
            </div>

            <div class="icon-container" @mouseenter="showUserDialog">
                <img src="../assets/user-icon.png" class="nav-user-icon">
                <div v-if="showUserInfo" ref="userInfoRef">
                    <div v-if="isLogin" class="drop-down">
                        <div style="height: 140px;padding-top: 20px;">
                            <img v-if="isLogin && imgUrl" :src="imgUrl" class="user-icon">
                            <img v-else src="../assets/default-user.png" class="user-icon">
                            <div style="font-size: 16px;color: #666666;font-weight: bold;margin-top: 10px;">已登录：
                                {{ userName }}
                            </div>
                        </div>
                        <div style="height: 80px;display: flex;align-items: center;justify-content: space-around;width: 100%;
                        background-color: #f1f7ff;border-bottom-right-radius: 5px;border-bottom-left-radius: 5px;user-select: none">
                            <div @click="toMyGamePage" style="display: flex;flex-direction: column;align-items: center;cursor: pointer;flex: 1;">
                                <img src="../assets/game-web.png" style="width: 25px;height: 25px;">
                                <span style="font-size: 13px;font-weight: bold;color: #888888;margin-top: 5px;">我的游戏</span>
                            </div>
                            <div @click="logout" style="display: flex;flex-direction: column;align-items: center;cursor: pointer;flex: 1;">
                                <img src="../assets/logout-web.png" style="width: 23px;height: 23px;">
                                <span style="font-size: 13px;font-weight: bold;color: #888888;margin-top: 5px;">退出</span>
                            </div>
                        </div>
                    </div>

                    <div v-else class="drop-down">
                        <img src="../assets/default-user.png" class="user-icon">
                        <div style="font-size: 16px;color: #888888;font-weight: bold;margin-top: 20px;">您还未登录
                            <span @click="openLoginPage"
                                  style="color: #f16868;margin-left: 15px;cursor: pointer;">立即登录</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showMask" ref="maskRef" @click="resetPage" class="mask"></div>
        <home-login v-if="showLogin"></home-login>

    </div>
</template>

<script>
    import {Dialog, Toast} from "vant";
    import HomeLogin from "@/components/HomeLogin.vue";
    import {webTokenRequest} from "@/api/request";

    export default {
        name: "HomeNav",
        components: {
            HomeLogin
        },
        data() {
            return {
                index: 0,
                preIndex: '',
                isLogin: false,
                showUserInfo: false,
                userName: '',
                imgUrl: '',
                showMask: false,
                showLogin: false,
                searchName: '',
                defaultSearchName: '灵眸极速服',
            }
        },
        mounted() {
            this.initActiveMenuIndex();
            this.preIndex = localStorage.getItem('preIndexMenu')
            //console.log('this.index:' + this.index + "    this.preIndex: " + this.preIndex);
        },
        methods: {
            toMyGamePage() {
                this.$router.push('/home/myGame');
            },
            resetPage() {
                this.showMask = false;
                this.showUserInfo = false
                this.showLogin = false;
            },
            openLoginPage() {
                this.showUserInfo = false;
                this.showLogin = true;
                this.showMask = true;
                this.$nextTick(()=> {
                    if (window.WheelEvent) {
                        this.$refs.maskRef.addEventListener('wheel', function(event) {
                            event.preventDefault();
                        }, { passive: false });
                    }
                });
            },
            searchGame() {
                let name = this.searchName || this.defaultSearchName;
                this.$router.push({path: '/home/searchGameList', query: {gameName: name}});
            },
            exit() {
                this.showMask = false;
                this.showUserInfo = false;
                localStorage.removeItem('web_token');
            },
            logout() {
                Dialog.confirm({
                    message: '确认退出登录吗？',
                }).then(() => {
                    let options = {
                        url: "/web/logout",
                        data: {}
                    };
                    webTokenRequest(options).then(res => {
                        Toast('已成功退出登录');
                        localStorage.removeItem('web_token');
                        localStorage.removeItem('web_user');
                        localStorage.removeItem('web_username');
                        localStorage.removeItem('web_headerImg');
                        this.resetPage();
                    });
                }).catch(() => {
                });
            },
            initActiveMenuIndex() {
                let path = this.$route.path;
                if (path === '/index') {
                    this.index = 1;
                }
                else if (path === '/home/gameList') {
                    this.index = 2;
                }
                else if (path === '/home/intro') {
                    this.index = 3;
                }
                else if (path === '/home/kf') {
                    this.index = 4;
                }
                else if (path === '/home/app') {
                    this.index = 5;
                }
            },
            showUserDialog() {
                this.isLogin = !!localStorage.getItem('web_token');
                this.userName = localStorage.getItem('web_username');
                this.imgUrl = localStorage.getItem('web_headerImg');
                this.showMask = true;
                this.showUserInfo = true;
                this.$nextTick(()=> {
                    if (window.WheelEvent) {
                        this.$refs.userInfoRef.addEventListener('wheel', function(event) {
                            event.preventDefault();
                        }, { passive: false });
                        this.$refs.maskRef.addEventListener('wheel', function(event) {
                            event.preventDefault();
                        }, { passive: false });
                    }
                });
            },
            toMainPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/index');
            },
            toGameListPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/gameList');
            },
            toIntroPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/intro');
            },
            toKfPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/kf');
            },
            toAppPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/app');
            },
            toAboutUsPage() {
                localStorage.setItem('preIndexMenu', this.index)
                this.$router.push('/home/us');
            },

        }
    }
</script>

<style scoped>
    /deep/.main-view {
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    /deep/.main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }

    .nav-center {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo-view {
        height: 80px;
        display: flex;
        align-items: center;
        font-size: 20px;
        color: #FFFFFF;
    }
    .menu-view {
        display: flex;
    }
    .menu {
        height: 80px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 18px;
        font-weight: bold;
        color: #555555;
        cursor: pointer;
        user-select: none;
        position: relative;
    }
    .menu-active {
        color: #7a69be;
    }
    .active-line {
        width: calc(100% - 40px);
        height: 4px;
        background-color: #7a69be;
        position: absolute;
        left: 20px;
        bottom: 0;

    }

    .from-right {
        animation: right_to_left .3s linear;
    }

    .from-left {
        animation: left_to_right .3s linear;
    }

    @keyframes left_to_right {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes right_to_left {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(0);
        }
    }

    .search-view {
        height: 40px;
        width: 200px;
        background: rgba(122, 105, 190, 0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 40px;
        padding: 0 5px 0 10px;
    }
    .search-input {
        background: transparent;
        outline: none;
        border: none;
        height: 30px;
        margin-left: 10px;
        width: 100px;
    }
    .search-button {
        height: 30px;
        width: 60px;
        background-color: #7a69be;
        color: #ffffff;
        border-radius: 30px;
        font-size: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        user-select: none;
    }
    .icon-container {
        margin-right: 20px;
        position: relative;
        display: inline-block;
    }
    .nav-user-icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
        margin-top: 2px;
    }
    .drop-down {
        position: absolute;
        top: 60px;
        right: -40px;
        background-color: white;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        padding: 30px 0 10px;
        z-index: 1002;
        width: 300px;
        height: 200px;
        box-sizing: border-box;
    }
    .user-icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
    }
    .mask {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100vh;
        width: 100vw;
        z-index: 1000;
        position: absolute;
    }
</style>