import serverArr from "@/api/servers";

const webConfig =  {

    //用户协议
    agreement :  "https://game.daoyigame.com/static/res/user.html",
    //隐私政策
    privacy :  "https://game.daoyigame.com/static/res/privacy.html",

}

export default webConfig