<template>
    <div class="main-view">
        <div class="m-title" ref="mainTitle">
            <div style="display: flex;height: 70px;align-items: center;justify-content: space-between;width: 100%;">
                <span>家族</span>
            </div>
        </div>

        <div style="width: 100%;margin: 0 auto;">
            <div v-if="!isLogin" class="status-view" style="font-size: 14px;color: #888888;">
                您还未登录，请在<span @click="openLogin" class="text-button">登录</span>之后查看家族信息
            </div>
            <div v-else-if="joinStatus === 0" class="status-view" style="font-size: 14px;color: #888888;">
                <div style="margin-top: 10px;">
                    当前还未加入任何家族
                    <span @click="showJoinInput = true;" class="text-button">我要加入家族</span>
                </div>
                <div v-if="showJoinInput" style="box-sizing: border-box;">
                    <van-field label="口令码" label-width="60" label-class="inline-label" placeholder="请输入口令码" type="text" maxlength="30"
                               style="width: 80%;background-color: rgba(162,162,162,0.13);margin: 20px auto;color: #ffffff;padding: 10px 20px;font-size: 14px;border-radius: 10px;" v-model="joinCode" clearable/>

                    <div class="box-button">
                        <van-button @click="doJoinTeam" type="info" block size="small" loading-text="提交中" :loading="loading" round style="width: 140px;font-size: 14px;"
                                    color="#7a69be">
                            加入家族
                        </van-button>
                    </div>

                </div>
            </div>

            <div v-else class="team-info-view">
                <div style="font-size: 18px;font-weight: bold;color: #333333">
                    {{teamInfo.org_name}}
                </div>
                <span class="info-title">当前任务</span>
                <div class="info-body">{{teamInfo.renwu}}</div>

                <span class="info-title">家族资金</span>
                <div class="info-body">{{teamInfo.balance}}</div>

                <span class="info-title">家族人数</span>
                <div class="info-body">{{teamInfo.memberCount}}</div>
            </div>
        </div>


        <div class="me-login-view" v-if="showLogin" @click.self="showLogin = false">
            <home-login></home-login>
        </div>
        <home-tabbar></home-tabbar>
    </div>
</template>

<script>


import homeTabbar from "@/components/HomeTabbar.vue";
import {webTokenRequest} from "@/api/request";
import {Toast} from "vant";
import HomeLogin from "@/components/HomeLogin.vue";

export default {
    name: 'Team.vue',
    components: {
        HomeLogin,
        homeTabbar
    },
    data() {
        return {
            loading: false,
            isLogin: false,
            showLogin: false,
            showJoinInput: false,
            joinStatus: 0,
            teamInfo: {},
            joinCode: '',
        };
    },
    mounted() {
        this.isLogin = !!localStorage.getItem('web_token');
        if (this.isLogin) {
            this.initTeamInfo();
        }
    },
    methods: {
        openLogin() {
            this.showLogin = true;
        },
        resetPage() {
            this.showLogin = false;
            this.isLogin = true;
            this.initTeamInfo();
        },
        initTeamInfo() {
            this.showLoading = true;
            let options = {
                url:"/org/getMyOrg",
                method: 'get',
                data: {}
            };
            webTokenRequest(options).then(res => {
                this.showLoading = false;
                if (res.data.success) {
                    this.teamInfo = res.data.data;
                    this.joinStatus = res.data.data.joinStatus;
                    console.log(this.joinStatus === 1)
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
        doJoinTeam () {
            if (!this.joinCode) {
                Toast('请输入家族口令码');
                return;
            }

            this.loading = true;
            let options = {
                url:"/org/joinOrg?code=" + this.joinCode,
                method: 'get',
                data: {}
            };
            webTokenRequest(options).then(res => {
                this.loading = false;
                if (res.data.success) {
                    Toast('加入家族成功');
                    this.initTeamInfo();
                }
                else {
                    Toast(res.data.error.message);
                }
            });
        },
    }
};
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
        position: relative;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }
    .m-title {
        display: flex;
    }
    .status-view {
        width: 90%;
        margin: 110px auto 0;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 15px 20px;
        box-sizing: border-box;
    }
    .team-info-view {
        width: 90%;
        margin: 110px auto 0;
        min-height: 120px;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 15px 20px;
        box-sizing: border-box;
    }
    .me-login-view {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
    }
    .text-button {
        margin: 0 5px;
        font-weight: bold;
        color: #7a69be;
        cursor: pointer;
        user-select: none;
    }
    .box-button {
        margin: 30px 0 10px;
        display: flex;
        justify-content: center;
    }
    .info-title {
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
        color: #666666;
        text-align: left;
    }
    .info-body {
        margin-top: 10px;
        background-color: rgba(162,162,162,0.13);
        text-align: left;
        font-size: 15px;
        padding: 15px;
        border-radius: 10px;
        color: #333333;
    }
</style>