<template>
    <div class="container">
        <div class="container_main">
            <div class="container_main_inner">
                <div style="margin-bottom: 40px;display: flex;flex-direction: column;justify-content: left;">
                    <img :src="logo" style="width:200px;height: 100px;transform: translateX(-20px);"/>
                    <div class="account_tips" style="margin-top: 10px;">使用{{ webname }}账号登录，绑定传奇账号</div>
                    <div class="account_tips">{{ webname }}账号绑定的微信会直接收到红包</div>
                    <div class="account_tips">更多福利请<a :href="websiteLink" target="_blank">点击跳转</a></div>
                    <div class="account_tips">兑换码：<span style="color: #f14343;user-select: text">霸星传奇</span></div>
                </div>
                <div class="form-control">
                    <input v-model="form.username" type="text" required="">
                    <label>
                        <span style="transition-delay:0ms">账号</span>
                    </label>
                </div>
                <div class="form-control">
                    <input v-model="form.password" type="password" required="">
                    <label>
                        <span style="transition-delay:0ms">密码</span>
                    </label>
                </div>
                <div :class="{'login_container': loading}">
                    <div class="login_btn" :class="{'login_btn_disable': loading}" @click="doBindCQ">
                        确认绑定
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="loading">
            <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center">
                <div style="width: 130px;height: 130px;border-radius: 5px; background-color: #333;display: flex;align-items: center;justify-content: center">
                    <van-loading type="spinner" vertical color="#d5d5d5" size="36px" style="user-select: none">
                        绑定中...
                    </van-loading>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import crypto from "@/utils/crypto";
import {Toast} from "vant";
import {request} from "@/api/request";
import Myloading from "@/components/myloading.vue";
import logo from "@/assets/logo/logo-main.png"

export default {
    name: "LegendaryBinding",
    data(){
        return {
            logo,
            cqAccount: '',
            gameId: '',
            form: {
                username: '',
                password: ''
            },
            accountPattern: /^[a-zA-Z0-9_]{6,12}$/,
            passwordPattern: /^[a-zA-Z0-9~!@#$%^&*()_+.]{6,20}$/,
            loading: false,
            websiteLink: 'https://game.daoyigame.com/#/index',
            webname: '星游'
        }
    },
    mounted() {
        this.cqAccount = this.$route.query.account;
    },
    methods: {
        doBindCQ() {
            if(!this.form.username || !this.form.password) {
                Toast.fail('请输入账号或密码')
                return;
            }
            if(!this.accountPattern.test(this.form.username) || !this.passwordPattern.test(this.form.password)) {
                Toast.fail("账号或密码格式错误")
                return;
            }
            this.loading = true;
            let options = {
                url: `/h5/bindCq`,
                data: {
                    userName: this.form.username,
                    password: crypto.encrypt(this.form.password),
                    code: this.cqAccount
                }
            }
            request(options).then(res => {
                if(res.data.success) {
                    Toast.success("绑定成功")
                }else {
                    Toast.fail(res.data.error.message)
                }
            }).finally(() => {
                this.loading = false;
            })
        },
    }
}
</script>
<style>
:root {
    --normal-color: #7a69be;
    --active-color: #6b5ba4;
    --hover-color: rgba(124, 107, 192, 0.8);
}
</style>
<style scoped>
.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.container_main {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 700px;
    min-width: 375px;
    min-height: 667px;
    border: 1px solid #d5d5d5;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container_main_inner {
    padding: 40px 15%;
    flex: 1;
}

.account_tips {
    color: #888888;
    text-align: left;
    font-size: 14px;
    user-select: none;
}

.account_tips a:link, a:visited, a:hover, a:active{
    color: var(--normal-color);
}

.login_btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: var(--normal-color);
    border-radius: 99px;
    color: #fff;
    margin-top: 80px;
    user-select: none;
    cursor:pointer
}

.login_btn:active {
    background-color: var(--active-color);
}
.login_btn:hover {
    background-color: var(--hover-color);
}
.login_container {
    cursor: not-allowed;
}
.login_btn_disable {
    pointer-events: none;
    background-color: var(--hover-color);
}

.form-control {
    position: relative;
    margin: 20px 0 40px;
    width: 100%;
    user-select: none;
}

.form-control input {
    background-color: transparent;
    border: 0;
    border-bottom: 2px #3a3a3a solid;
    display: block;
    width: 100%;
    padding: 15px 0;
    font-size: 18px;
    color: #3a3a3a;
}

.form-control input:focus,
.form-control input:valid {
    outline: 0;
    border-bottom-color: #7a69be;
}

.form-control label {
    position: absolute;
    top: 15px;
    left: 0;
    pointer-events: none;
}

.form-control label span {
    display: inline-block;
    font-size: 18px;
    min-width: 5px;
    color: #3a3a3a;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.form-control input:focus+label span,
.form-control input:valid+label span {
    color: #7a69be;
    transform: translateY(-30px);
}

@media only screen and (max-width: 600px) {
    .container_main {
        border: 0;
    }
}
</style>