import Vue from 'vue'

import Router from 'vue-router'

import GameMain from './views/game/GameMain';
import GameRegister from './views/game/GameRegister';
import GameReset from './views/game/GameReset';

import WxPayQrCode from './views/webview/WxPayQrCode';

import HomeIndex from './views/home/Index';
import GameList from './views/home/GameList';
import HomeIntro from './views/home/Intro';
import HomeKf from './views/home/Kf';
import HomeApp from './views/home/DownloadApp';
import HomeDetail from './views/home/Detail';
import HomeMe from './views/home/Me';
import HomeTeam from './views/home/Team';
import AppRealName from './views/home/AppRealName';
import AppPassword from './views/home/AppPassword';
import AppTel from './views/home/AppTel';
import MyGame from './views/home/MyGame.vue';
import AppSearch from './views/home/AppSearch.vue';
import SearchGameList from './views/home/SearchGameList';
import AppAbout from './views/home/AppAbout';


import Test from './views/game/test';

import WeixinJSPay from './views/open/WeixinJSPay';
import GameSpeedH5 from "@/views/home/GameSpeedH5.vue";
import Selfpaywx from "@/views/home/Selfpaywx.vue";
import BindWx from "@/views/home/BindWx.vue";
import SpaceHelpPage from "@/views/app/SpaceHelpPage.vue";
import LegendaryBinding from "@/views/home/LegendaryBinding.vue";

Vue.use(Router)

export default new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/h5game/*',
      name: 'GameMain',
      component: GameMain
    },
    {
      path: '/detail/*',
      name: 'HomeDetail',
      component: HomeDetail
    },
    {
      path: '/h5/register',
      name: 'GameRegister',
      component: GameRegister
    },
    {
      path: '/h5/reset',
      name: 'GameReset',
      component: GameReset
    },
    {
      path: '/wxPayQrCode',
      name: 'WxPayQrCode',
      component: WxPayQrCode
    },
    {
      path: '/index',
      name: 'HomeIndex',
      component: HomeIndex
    },
    {
      path: '/home/gameList',
      name: 'GameList',
      component: GameList
    },
    {
      path: '/home/intro',
      name: 'HomeIntro',
      component: HomeIntro
    },
    {
      path: '/home/kf',
      name: 'HomeKf',
      component: HomeKf
    },
    {
      path: '/home/app',
      name: 'HomeApp',
      component: HomeApp
    },
    {
      path: '/home/realName',
      name: 'AppRealName',
      component: AppRealName
    },
    {
      path: '/home/password',
      name: 'AppPassword',
      component: AppPassword
    },
    {
      path: '/home/tel',
      name: 'AppTel',
      component: AppTel
    },
    {
      path: '/home/myGame',
      name: 'MyGame',
      component: MyGame
    },
    {
      path: '/home/appSearch',
      name: 'AppSearch',
      component: AppSearch
    },
    {
      path: '/home/searchGameList',
      name: 'SearchGameList',
      component: SearchGameList
    },
    {
      path: '/home/about',
      name: 'AppAbout',
      component: AppAbout
    },


    {
      path: '/home/team',
      name: 'HomeTeam',
      component: HomeTeam
    },
    {
      path: '/home/me',
      name: 'HomeMe',
      component: HomeMe
    },
    {
      path: '/weixinJSPay',
      name: 'WeixinJSPay',
      component: WeixinJSPay
    },
    {
      path: '/game/test',
      name: 'Test',
      component: Test
    },
    {
      path: '/game/speed',
      name: 'GameSpeedH5',
      component: GameSpeedH5
    },
    {
      path: '/auto/rechargeWx',
      name: 'AutoRechargeWx',
      component: Selfpaywx
    },
    {
      path: '/bind/wechat',
      name: 'BindWx',
      component: BindWx
    },
    {
      path: '/space/helpPage',
      name: 'SpaceHelpPage',
      component: SpaceHelpPage
    },
    {
      path: '/cqbd',
      name: 'LegendaryBinding',
      component: LegendaryBinding
    }
  ]
})
