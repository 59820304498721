<template>
    <div class="main-view">
        <home-nav class="m-hidden-view"></home-nav>
        <gzh-code-view class="m-hidden-view"></gzh-code-view>

        <div class="m-title">
            <div style="display: flex;height: 70px;align-items: center;">
                <van-icon @click="back" style="margin-right: 15px;" name="arrow-left" size="25"/>
                联系客服
            </div>
        </div>

        <div style="width: 100%;min-height: calc(100vh - 380px); max-width: 900px;margin: 0 auto;">
            <div class="m-hidden-view" style="width: 100%;height:70px; background-color: #ffffff;border-radius: 10px;margin-top: 20px;">
                <div style="height: 70px;padding-left: 50px;display: flex;align-items: center;color: #666666">
                    <span>当前位置：</span>
                    <span style="margin-left: 10px;">首页</span>
                    <span style="margin-left: 10px;">></span>
                    <span style="margin-left: 10px;">联系客服</span>
                </div>

            </div>

            <div class="kf-view">
                <div class="common-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            QQ客服
                        </div>
                    </div>

                    <div class="kf-item-view">
                        <div class="kf-img-view">
                            <img src="../../assets/qq.png" class="kf-img">
                        </div>
                        <div class="kf-info">
                            <div>
                                QQ: 3758985932
                                <span @click="openQQKf('3758985932')" class="copy-button">咨询</span>
                            </div>
                            <div style="color: #888888;font-size: 14px;">
                                工作时间  9:00 - 18:00
                            </div>

                        </div>
                    </div>


                </div>
                <div class="common-view">
                    <div class="top-view-head">
                        <div class="top-view-name_pre"></div>
                        <div class="top-vew-name">
                            微信客服
                        </div>
                    </div>
                    <div class="kf-item-view m-hidden-view">
                        <img src="../../assets/gzh.jpg" style="width: 140px;height: 140px;">
                        <div class="kf-info">
                            <div style="font-size: 15px;">请扫码关注公众号进入客服页面</div>
                            <div style="color: #888888;font-size: 14px;">
                                工作时间  9:00 - 18:00
                            </div>
                        </div>

                    </div>

                    <div class="kf-item-view p-hidden-view">
                        <img src="../../assets/gzh.jpg" class="kf-img">
                        <div class="kf-info">
                            <div style="text-align: left;font-size: 12px;">关注"星游纪元科技"公众号，联系客服</div>
                            <div style="color: #888888;">
                                工作时间  9:00 - 18:00
                            </div>
                        </div>
                    </div>

                </div>
                <div class="common-view m-hidden-view" style="display: flex;align-items: center;justify-content: center;">
                    <div style="font-size: 25px;color: #888888">更多服务即将完善</div>
                </div>
            </div>


        </div>

        <home-footer class="m-hidden-view"></home-footer>
    </div>
</template>

<script>
    import homeNav from "@/components/HomeNav";
    import homeFooter from "@/components/HomeFooter";
    import gzhCodeView from "@/components/GzhCodeView";
    import notice from "@/api/notice";

    export default {
        name: "HomeKf",
        components: {
            homeNav,
            homeFooter,
            gzhCodeView,
        },
        data() {
            return {}
        },
        mounted() {

        },
        methods: {
            openQQKf(qq) {
                let qqUrl = 'mqq://im/chat?chat_type=wpa&uin=' + qq + '&version=1&src_type=web';
                notice.openUrl(qqUrl)
            },
            back() {
                this.$router.back();
            },
        }
    }
</script>

<style scoped>
    .main-view {
        width: 100%;
        height: 100vh;
        overflow-y: scroll;
        background-color: #eaeef3;
        position: relative;
        -ms-overflow-style: none;  /* IE 和 Edge */
        scrollbar-width: none;/* Firefox */
    }
    .main-view::-webkit-scrollbar {
        display: none;/* 兼容 Chrome, Opera, 和 Safari */
    }
    .kf-view {
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .common-view {
        width: 290px;
        height: 310px;
        background-color: #ffffff;
        border-radius: 10px;
        margin-top: 20px;
        margin-bottom: 30px;
    }
    .top-view-name_pre {
        width: 5px;
        height: 20px;
        background-color: #f55050;
    }
    .top-vew-name {
        text-align: left;
        color: #333333;
        user-select: none;
        margin-left: 10px;
    }
    .top-view-head {
        height: 60px;
        font-size: 22px;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }
    .kf-item-view {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .kf-img-view {
        width: 140px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .kf-img {
        width: 90px;
        height: 90px;
        border-radius: 12px;
    }
    .kf-info {
        color: #333333;
        margin-top: 10px;
        font-size: 15px;
        display: flex;
        flex-direction: column;
        height: 45px;
        justify-content: space-between;
    }
    .copy-button {
        color: #ee3a3a;
        margin-left: 10px;
        font-size: 16px;
        display: none;
        user-select: none;
        cursor: pointer;
    }
    @media screen and (max-width: 900px) {
        .main-view {
        }

        .m-title {
            display: flex;
            font-size: 20px;
        }

        .p-title {
            display: none;
        }
        .kf-view {
            flex-direction: column;
            margin-top: 100px;
        }
        .m-hidden-view {
            display: none !important;
        }
        .top-view-head {
            font-size: 16px;
            height: 45px;
        }
        .kf-item-view {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 15px 20px;
        }
        .kf-img-view {
            width: 50px;
            height: 50px;
        }
        .kf-img {
            width: 50px;
            height: 50px;
            margin-top: 0;
        }
        .kf-info {
            margin-top: 0;
            margin-left: 10px;
            align-items: flex-start;
            font-size: 14px;
        }
        .common-view {
            width: 90%;
            margin-top: 10px;
            height: unset;
            margin-bottom: 10px;
        }
        .copy-button {
            display: unset;
            font-size: 14px;
        }
        .top-view-name_pre {
            width: 3px;
            height: 13px;
        }
    }
    @media screen and (min-width: 900px) {
        .p-hidden-view {
            display: none;
        }
    }
</style>